<template>
    <v-dialog
        v-model="dialogVideo"
        :content-props="contentProps"
    >
        <template #activator="{ props }">
            <div
                v-if="videoId && !slots.default"
                v-bind="$attrs"
                class="yt-dialog-default-activator position-relative cursor-pointer"
            >
                <img
                    v-bind="props"
                    :src="`https://i.ytimg.com/vi_webp/${videoId}/maxresdefault.webp`"
                    loading="lazy"
                    alt="YouTube Video Thumbnail"
                    class="
                        object-fit-contain
                        w-100
                        h-100
                    "
                />
                <v-icon
                    size="64px"
                    :icon="mdiPlayCircle"
                    class="no-event absolute-center"
                    
                ></v-icon>
            </div>
            <template
                v-else
            >
                <slot
                    name="default"
                    :props="props"
                ></slot>
            </template>
        </template>
        <div
            class="video-dialog-main"
        >
            <v-btn
                class="dialog-close cursor-pointer opacity-100"
                variant="plain"
                @click="dialogVideo = false"
                icon
            >
                <v-icon
                    color="white"
                    size="x-large"
                    :icon="mdiCloseCircle"
                ></v-icon>
            </v-btn>
            <template
                v-if="videoId"
            >
                <v-responsive
                    :aspect-ratio="16 / 9"
                    max-width="100vw"
                    max-height="100vh"
                >
                    <iframe
                        v-if="dialogVideo"
                        frameborder="0"
                        scrolling="no"
                        type="text/html"
                        :src="`https://www.youtube.com/embed/${videoId}?autoplay=1`"
                        allow="autoplay"
                        allowfullscreen
                    ></iframe>
                </v-responsive>
            </template>
            <template
                v-else-if="videoUrl"
            >
                <video
                    class="video-dialog-video-elm max-width-100 max-height-100"
                    playsinline
                    autoplay
                    muted
                    loop
                >
                    <source
                        :src="videoUrl"
                    >
                    <track kind="captions">
                </video>
            </template>
            <template
                v-else
            >
                <div
                    class="bg-black text-center pa-12 font-size-5"
                >
                    Video Not Found
                </div>
            </template>
        </div>
    </v-dialog>
</template>

<script lang="ts" setup>
    import { mdiCloseCircle, mdiPlayCircle } from '@mdi/js';

    defineOptions({
        inheritAttrs: false
    });

    withDefaults(
        defineProps<{
            videoId?: Nilish<string>;
            videoUrl?: Nilish<string>;
            contentProps?: Record<string, unknown>;
        }>(),
        {
            videoId: ''
        }
    );

    const emit = defineEmits<{
        'change': [value: boolean];
    }>();

    const slots = useSlots();
    const dialogVideo = ref(false);

    const unWatchDialogVideo = watch(dialogVideo, (newVal) => {
        emit('change', newVal);
    });

    onBeforeUnmount(() => {
        unWatchDialogVideo();
    });
</script>

<style lang="scss" scoped>
iframe {
    background-color: #000;
    width: 100%;
    height: 90vh;
    max-width: 100vw;
    max-height: 100vh;
}

.dialog-close {
    position: absolute;
    top: -24px;
    right: -24px;
    z-index: 1;
}
</style>

<style lang="scss">
.yt-dialog-default-activator {
    .v-icon {
        color: rgb(var(--v-theme-on-surface));
        background-color: rgb(var(--v-theme-surface));
        border-radius: 50%;
        padding: 0;
        opacity: 0.8;
    }
}
</style>
